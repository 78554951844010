import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: Number(process.env.VUE_APP_API_TIMEOUT), // 70 segundos si VUE_APP_API_TIMEOUT es 70000
  headers: {
    'Content-Type': 'application/json',
  },
});

const sendDeletionAccountEmail = async (email, locale) => {
  try {
    console.log(process.env.VUE_APP_API_BASE_URL);
    const response = await axiosInstance.post('/requestEmailDeletion/', {
      email,
      locale,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const checkAdmin = async (email, token) => {
  try {
    const response = await axiosInstance.get('/checkAdmin/', {
      params: {
        email,
        token,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const confirmAccountDeletion = async (email, locale, token) => {
  try {
    const response = await axiosInstance.get('/deleteAccount/', {
      params: {
        email,
        locale,
        token,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  sendDeletionAccountEmail,
  confirmAccountDeletion,
  checkAdmin,
};
