export default {
  "devicesTable": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargadores"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupación"])},
    "createSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear agrupación"])},
    "addCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir cargador"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear agrupación"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "serialNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Serie..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Agrupación"])},
    "groupNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de Agrupación..."])},
    "undoGrouping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer Agrupación"])},
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desplegar"])},
    "deleteCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Cargador"])},
    "removeFromGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacar de Agrupación"])},
    "deviceImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen del Dispositivo"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitado"])},
    "remindApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordar Aprobación"])},
    "addToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a Grupo"])},
    "confirmDeleteCharger": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar acceso a cargador "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>¡ Perderás el acceso al cargador </b>!<br/><br/>Para recuperarlo, tendrás que solicitar que lo vuelvan a añadir."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])}
    },
    "confirmDeleteGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar agrupación"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cargadores aparecen ahora fuera de la agrupación."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])}
    },
    "confirmAddCharger": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargador ya existe"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tienes en tu lista un cargador con ese número de serie."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Añadir cargador?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se enviará una solicitud para añadir el cargador. El cargador.quedará como Pendiente hasta que la solicitud sea aprobada."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      },
      "notFound": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargador no encontrado"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de serie no se ha encontrado en la base de datos."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])}
      }
    },
    "confirmAddGroup": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo ya existente"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tienes una agrupación en tu lista con ese nombre."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Agregar agrupación?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se creara la nueva agrupación."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      }
    },
    "confirmSendReminder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordar aprobación"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se enviará un recordatorio para aprobar el acceso al cargador."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "confirmRemoveFromGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sacar de agrupación. Cargador "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cargador aparecerá fuera de la agrupación."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
    },
    "confirmAddToGroup": {
      "noGroups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay agrupaciones creadas."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa el botón + para crear una nueva agrupación.'"])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      },
      "addToGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a agrupación"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cargador aparecerá dentro de la agrupación."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
      }
    },
    "searchingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscando cargador..."])},
    "searchingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espera mientras comprobamos los datos..."])}
  },
  "footer": {
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso Legal"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])}
  },
  "header": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])}
  },
  "home": {
    "1year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 año"])},
    "30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 días"])},
    "7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 días"])},
    "chargeRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargas"])},
    "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde:"])},
    "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta:"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días"])},
    "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mes"])},
    "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta semana"])},
    "thisYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este año"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard LITE"])},
    "tabAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizado"])},
    "tabSupervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
    "tabAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])}
  },
  "login": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu email"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He olvidado mi contraseña"])},
    "invalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenciales incorrectas"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordarme"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login correcto!"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espera mientras comprobamos los datos."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate"])}
  },
  "recordsTable": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
    "exportColumnCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargador"])},
    "exportColumnDeviceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "exportColumnEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
    "exportColumnEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia (Wh)"])},
    "exportColumnStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "exportColumnUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
    "exportColumnGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupación"])},
    "exportColumnCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 (Kg)"])},
    "filterPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por día"])},
    "filterPerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por mes"])},
    "tabDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARGADORES"])},
    "tabDevicesEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energía"])},
    "tabDevicesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tabDevicesName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "tabRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECARGAS"])},
    "tabRecordsCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargador"])},
    "tabRecordsEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
    "tabRecordsEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energía"])},
    "tabRecordsStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "tabUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USUARIOS"])},
    "tabUsersEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energía"])},
    "tabUsersName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargas y consumos"])},
    "sheetTitle0 ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "sheetTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargas"])},
    "sheetTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por cargador"])},
    "sheetTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por usuario"])},
    "sheetTitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por agrupación"])},
    "sheetGeneralDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde"])},
    "sheetGeneralDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta"])},
    "sheetGeneralTotalRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargas totales:"])},
    "sheetGeneralTotalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energía total (KwH):"])},
    "sheetGeneralTotalCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 total (Kg):"])},
    "sheetChargersTableSerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de serie"])},
    "sheetChargersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "sheetChargersTableType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "sheetUsersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "sheetUsersTableEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "reset": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "checkInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprueba tu correo"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu mail"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te hemos enviado instrucciones para resetear tu contraseña."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espera mientras comprobamos los datos."])}
  },
  "register": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "checkInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprueba tu correo"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce tu mail"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te hemos enviado instrucciones para resetear tu contraseña."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espera mientras comprobamos los datos."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])}
  },
  "usersTable": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])}
  },
  "modal": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
  },
  "request_account_deletion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar eliminación de cuenta de App Veltium"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al hacer clic en el botón de abajo, solicitarás la eliminación de tu cuenta de la App Veltium."])},
    "message2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibirás un correo electrónico con instrucciones sobre el proceso de eliminación."])},
    "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar solicitud"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, espera mientras comprobamos los datos."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "selectNewAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar nuevo administrador para el cargador "])},
    "mailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud enviada"])},
    "mailSentMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha enviado un correo a tu bandeja de entrada con instrucciones para eliminar tu cuenta."])},
    "mailSentMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no lo encuentras, revisa la carpeta de spam y revisa que has introducido correctamente tu dirección de correo."])},
    "mailSentMessage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si sigues sin recibir el correo, por favor, contacta con nosotros en "])}
  },
  "confirm_account_deletion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm account deletion"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your account?"])},
    "message2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicking on the button below will delete your account."])},
    "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm deletion!!!"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! we are processing the request."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "selectNewAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a new administrator for the charger"])},
    "linkInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El enlace es inválido o ha expirado."])}
  }
}