// import axios from 'axios';
import {
  auth, db, dataPrefix, firebase, functions,
} from '@/includes/firebase';

export default {
  state: {
    userLoggedIn: false,
    userLogged: {},
    // token: '',
  },
  getters: {

  },
  mutations: {
    toggleAuth: (state) => {
      state.userLoggedIn = !state.userLoggedIn;
    },
    setUserLogged: (state, payload) => {
      state.userLogged = payload;
    },
    // setToken: (state, payload) => {
    //   state.token = payload;
    // },

  },
  actions: {
    async login({ commit }, payload) {
      const result = await auth.signInWithEmailAndPassword(payload.email, payload.password);
      const token = await result.user.getIdToken();
      // log in console access token and refresh token
      if (token) console.log('TOKEN');

      if (payload.persist === 'persist') {
        auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      } else {
        auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      }
      commit('toggleAuth');
      // TODO: see if we can access data node or not
      // await this.getUserDetails(auth.currentUser);
      commit('setUserLogged', { uid: auth.currentUser.uid, email: auth.currentUser.email });
      // commit('setToken', await auth.currentUser.getIdToken());
    },
    async init_login({ commit }) {
      const user = auth.currentUser;
      if (user) {
        commit('toggleAuth');
        commit('setUserLogged', { uid: auth.currentUser.uid, email: auth.currentUser.email });
      }
    },
    async signout({ commit }) {
      commit('toggleAuth');
      await auth.signOut();
    },
    async getUserDetails({ commit }, user) {
      // fetch user details
      const { uid } = user;
      // uid = 'yMTPW3hD2WPkJeYnwQBuR6AlHtg1'; // Traxall
      // uid = '0tTXOENmoGW5ZjYvlHT7YF4QJ6D3';
      const refdata = db.ref(`/${dataPrefix}/users/${uid}/data`);
      const dataSnapshot = await refdata.get();
      const userValues = [];
      userValues.push(dataSnapshot.child('name_cy').val());
      userValues.push(dataSnapshot.child('surname_cy').val());
      userValues.push(dataSnapshot.child('email_cy').val());
      const requestData = {
        user_id: user.uid,
        user_values: userValues,
      };

      // Call gcloud function to decrypt user data
      // Get a reference to the callable function
      const decryptUserData = functions.httpsCallable('decryptUserData');

      // Call the function
      const result = await decryptUserData(requestData);
      const { data } = result;
      const myUser = {
        uid: user.uid,
        email: data.user_values[2] ? data.user_values[2] : user.email,
        name: `${data.user_values[0]} ${data.user_values[1]}`,
        locale: dataSnapshot.child('locale').val(),
      };
      commit('setUserLogged', myUser);
    },
  },
  async getUserDetails_OLD({ commit }, user) {
    // fetch user details
    // const uid = 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx';
    // const refdata = db.ref(`/${dataPrefix}/users/${uid}/data`);
    const refdata = db.ref(`/${dataPrefix}/users/${user.uid}/data`);
    const dataSnapshot = await refdata.get();
    const userValues = [];
    let myUser = {};

    // check if user has data on name_cy, surname_cy and email_cy
    // if so, then we need to decrypt the data

    if (dataSnapshot.child('name_cy').val() && dataSnapshot.child('surname_cy').val() && dataSnapshot.child('email_cy').val()) {
      userValues.push(dataSnapshot.child('name_cy').val());
      userValues.push(dataSnapshot.child('surname_cy').val());
      userValues.push(dataSnapshot.child('email_cy').val());

      const requestData = {
        user_id: user.uid,
        user_values: userValues,
      };

      // Call gcloud function to decrypt user data
      // Get a reference to the callable function
      const decryptUserData = functions.httpsCallable('decryptUserData');

      // Call the function
      const result = await decryptUserData(requestData);
      const { data } = result;
      myUser = {
        uid: user.uid,
        email: data.user_values[2] ? data.user_values[2] : user.email,
        name: `${data.user_values[0]} ${data.user_values[1]}`,
        locale: dataSnapshot.child('locale').val(),
      };
    } else {
      myUser = {
        uid: user.uid,
        email: dataSnapshot.child('email').val(),
        name: `${dataSnapshot.child('name').val()} ${dataSnapshot.child('surname').val()}`,
        locale: dataSnapshot.child('locale').val(),
      };
    }
    commit('setUserLogged', myUser);
  },

};
