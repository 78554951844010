import { createApp } from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import App from './App.vue';
import store from './store';
// import './assets/css.css';
import i18n from './includes/i18n';
import ProgressBar from './includes/progress-bar';
import 'nprogress/nprogress.css';
import { auth } from './includes/firebase';
import VeeValidatePlugin from './includes/validation';
import router from './router';

ProgressBar(router);

let app;

auth.onAuthStateChanged(() => {
  if (!app) {
    const options = {
      confirmButtonColor: '#41b882',
      cancelButtonColor: '#ff7674',
    };
    app = createApp(App).use(i18n);
    app.use(store);
    app.use(router);
    app.use(VeeValidatePlugin);
    app.use(VueSweetalert2, options);
    app.mount('#app');
  }
});
