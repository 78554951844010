<template>

   <!-- ------------------- PANEL-ESTADISTICAS ------------------- -->
<div class="panel-estadisticas">
<div class="panel-estadisticas-arriba">
<div class="flex">

<div class="panel-row">
<div class="panel-celda-icono">
    <img id="ico-estadisticas" src="assets/img/ico-estadisticas.svg">
</div>
</div>

<div class="panel-row">
<div class="panel-titulo">{{$t('recordsTable.title')}}</div>
</div>

<div class="panel-row">
<div class="panel-celda-inputdate" >
  <input type="button" :class="[recsByDate.length===0 ? 'button-inactivo': 'button']"
  @click="exportToExcel(csvData,csvData2,csvData3,csvData4)" :value="$t('recordsTable.export')"
  :disabled="recsByDate.length===0"></div>
  <!-- <circle2 :size="'25px'" :stroke="'5px'" :background="'#c5c5c5'" :color="'#84BD00'"
    v-show="exporting"></circle2> -->

</div>

</div>

<!-- date shortcuts -->
<div class="date-shortcuts-container">
  <div class="date-shortcuts-cell">
    <a :class="[isActive('perDay') ? 'date-shortcuts-link-activo': 'date-shortcuts-link']"
    href="#"
    test-data="linkByDay"
    @click="setGraphPerDay">{{$t('recordsTable.filterPerDay')}}</a></div>
  <div class="date-shortcuts-cell" >
    <a :class="[isActive('perMonth') ? 'date-shortcuts-link-activo': 'date-shortcuts-link']"
    href="#"
    test-data="linkByMonth"
    @click="setGraphPerMonth">{{$t('recordsTable.filterPerMonth')}}</a></div>
</div>
<!-- /date shortcuts -->

<!-- ------------------- GRAFICO DE BARRAS PER DAY------------------- -->
<!-- PER DAY-->
<div class="grafico" v-show="graphTab==='perDay'">
<!-- NUEVO GRAFICO -->

<!-- ESCALA Y -->
<div class="mq-grafico noscroll" style="width:3rem;">
  <div class="mq-barra-container pinattop">
    <div class="mq-barra-bean-container">
      <div  id="tramoescala1" test-data="tramoescala1" v-show="maxScaleY>0"
          class="tramoescala">{{Math.floor(maxScaleY)}}
      </div>
      <div  id="tramoescala2" test-data="tramoescala2" v-show="maxScaleY>0"
          class="tramoescala">{{Math.floor(maxScaleY*0.75)}}
      </div>
      <div  id="tramoescala3" test-data="tramoescala3" v-show="maxScaleY>0"
          class="tramoescala">{{Math.floor(maxScaleY*0.50)}}
      </div>
      <div  id="tramoescala4" test-data="tramoescala4" v-show="maxScaleY>0"
          class="tramoescala">{{Math.floor(maxScaleY*0.25)}}
      </div>
    </div>
    <div class="mq-barra-pie noborder" v-show="maxScaleY>0"
        style="padding-top:0px;">0
    </div>
  </div>
</div>
<!-- /ESCALA Y -->

<div class="mq-grafico" id="graficoday"><!-- tiene el scroll -->

<!-- *** BARRA REFERENCIA DE ESCALA (siempre presente tal como esta escrito) *** -->
<div class="mq-barra-container nowidth transparente" style="border-width:1px;">
<div class="mq-barra-bean-container">
<div style="height:25%;" class="mq-bean" id="testigoaltura">&nbsp;</div></div>
<div class="mq-barra-pie"></div>
</div>
<!-- *** /BARRA REFERENCIA DE ESCALA (siempre presente tal como esta escrito) *** -->

<!-- BARRA -->

<template v-for="(data,index) in recsByDayView" :key="data" >
  <div class="mq-barra-container" v-if="(data.day!==''
    && data.day!=='NEWMONTH' && data.day!=='NEWYEAR')">
          <!-- GRID (LAYER) -->
      <div v-if="index===0" class="grid-container">
      <hr class="mq-grid" id="grid1" />
      <hr class="mq-grid" id="grid2" />
      <hr class="mq-grid" id="grid3" />
      <hr class="mq-grid" id="grid4" />
      </div>
      <!-- /GRID (LAYER) -->

      <div class="mq-barra-bean-container">
        <div class="mq-bean"
        :title='`${data.day}:&nbsp;${$n(data.energy/1000, `decimal`)} kWh`'
        :style="{height: `${data.energy*100/this.maxEnergy}%`}"
        v-if="data.day!==''  && data.energy!==0"
        @click.prevent="scrollToDay(data.day)">&nbsp;
        </div>
      </div>
      <div class="mq-barra-pie"
        v-if="(data.day!=='' && data.day!=='NEWMONTH' && data.day!=='NEWYEAR')">
        {{data.dayNum}}
      </div>
  </div>
  <!-- /BARRA -->

  <!-- BARRA SEPARADOR -->
  <div class="mq-separador-container"
    style="max-width:1%;" v-if="data.day==='NEWMONTH'">
  <div class="mq-barra-bean-container"
    style="border-style:dashed;border-left-width:1px; border-color:#84BD00;"
      >
    <div
      style="height:100%; max-width:0px; font-size:0.8rem; color:#AAAAAA;padding-left:0.2rem;">
      {{(new Date(data.cMonthStr).
        toLocaleString(this.$i18n.locale, { month: 'long' }))
      }}&nbsp;{{
        new Date(data.cMonthStr).getFullYear()}}
      </div>
  </div>
  <div class="mq-barra-pie">&nbsp;</div>

  </div>
  <!-- /BARRA SEPARADOR -->
  </template>

</div><!-- /tiene el scroll -->

<!-- NUEVO GRAFICO -->

</div>
<!-- PER DAY-->
<!-- ------------------- /GRAFICO DE BARRAS PER DAY------------------- -->

<!-- ------------------- GRAFICO DE BARRAS PER MONTH------------------- -->
<!-- PER MONTH-->
<div class="grafico" v-show="graphTab==='perMonth'">
<!-- NUEVO GRAFICO -->

<!-- ESCALA Y -->
<div class="mq-grafico noscroll" style="width:3rem;">
  <div class="mq-barra-container pinattop">
    <div class="mq-barra-bean-container">
      <div  id="tramoescalames1" test-data="tramoescalames1" v-show="maxScaleYMonth>0"
          class="tramoescala">{{Math.floor(maxScaleYMonth)}}
      </div>
      <div  id="tramoescalames2" test-data="tramoescalames2" v-show="maxScaleYMonth>0"
          class="tramoescala">{{Math.floor(maxScaleYMonth*0.75)}}
      </div>
      <div  id="tramoescalames3" test-data="tramoescalames3" v-show="maxScaleYMonth>0"
          class="tramoescala">{{Math.floor(maxScaleYMonth*0.50)}}
      </div>
      <div  id="tramoescalames4" test-data="tramoescalames4" v-show="maxScaleYMonth>0"
          class="tramoescala">{{Math.floor(maxScaleYMonth*0.25)}}
      </div>
    </div>
    <div class="mq-barra-pie noborder" v-show="maxScaleYMonth>0"
        style="padding-top:0px;">0
    </div>
  </div>
</div>
<!-- /ESCALA Y -->

<div class="mq-grafico" id="graficomonth"><!-- tiene el scroll -->

<!-- *** BARRA REFERENCIA DE ESCALA (siempre presente tal como esta escrito) *** -->
<div class="mq-barra-container nowidth transparente" style="border-width:1px;">
<div class="mq-barra-bean-container">
<div style="height:25%;" class="mq-bean" id="testigoalturames">&nbsp;</div></div>
<div class="mq-barra-pie"></div>
</div>
<!-- *** /BARRA REFERENCIA DE ESCALA (siempre presente tal como esta escrito) *** -->

<!-- BARRA -->

<template v-for="(data,index) in recsByMonth" :key="data" >
  <div class="mq-barra-container" v-if="data.cMonthStr!==''
   &&  data.cMonthStr.substr(0,7)!=='NEWYEAR'">
          <!-- GRID (LAYER) -->
      <div v-if="index===0" class="grid-container">
      <hr class="mq-grid" id="gridmes1" />
      <hr class="mq-grid" id="gridmes2" />
      <hr class="mq-grid" id="gridmes3" />
      <hr class="mq-grid" id="gridmes4" />
      </div>
      <!-- /GRID (LAYER) -->

      <div class="mq-barra-bean-container">
        <div class="mq-bean"
        :title='`${data.cMonthStr}:&nbsp;${$n(data.energy/1000, `decimal`)} kWh`'
        :style="{height: `${data.energy*100/this.maxEnergyMonth}%`}"
        v-if="data.month!==''  && data.energy!==0">
        </div>
      </div>
      <div class="mq-barra-pie"
        v-if="data.cMonthStr!=='' &&  data.cMonthStr.substr(0,7)!=='NEWYEAR'">
        {{new Date(data.cMonthStr).toLocaleString([], { month: 'short' })}}
      </div>
  </div>
  <!-- /BARRA -->

  <!-- BARRA SEPARADOR -->
  <div class="mq-separador-container"
    style="max-width:1%;" v-if="data.cMonthStr.substr(0,7)==='NEWYEAR'">
  <div class="mq-barra-bean-container"
    style="border-style:dashed;border-left-width:1px; border-color:#84BD00;"
      >
    <div
      style="height:100%; max-width:0px; font-size:0.8rem; color:#AAAAAA;padding-left:0.2rem;">
      {{(data.cMonthStr.substr(-4))}}</div>
  </div>
  <div class="mq-barra-pie">&nbsp;</div>

  </div>
  <!-- /BARRA SEPARADOR -->
  </template>

</div><!-- /tiene el scroll -->

<!-- NUEVO GRAFICO -->

</div>
<!-- PER MONTH-->
<!-- ------------------- /GRAFICO DE BARRAS PER MONTH------------------- -->
</div><!-- CIERRO PANEL-ARRIBA -->
<div class="panel-estadisticas-abajo"><!-- ABRO NUEVO DIV -->
<!-- ------------------- TABS ------------------- -->

<!-- TAB RECARGAS -->
<div id="tab1" v-show="tab==='records'">
<div class="tab-set">

<div class="tab-activa" test-data="recordsTab1"
  @click.prevent="tab='records'">{{$t('recordsTable.tabRecords')}}</div>
<div class="tab-inactiva" test-data="devicesTab1"
  @click.prevent="tab='chargers'">{{$t('recordsTable.tabDevices')}}</div>
<div class="tab-inactiva" test-data="usersTab1"
  @click.prevent="tab='users'">{{$t('recordsTable.tabUsers')}}</div>

</div>
<div class="tabla-tabs" >

<!-- TABLA HEADER -->
<div class="tabla-row-header">
<div class="tabla-cell"><strong>{{$t('recordsTable.tabRecordsStart')}}</strong></div>
<div class="tabla-cell"><strong>{{$t('recordsTable.tabRecordsEnd')}}</strong></div>
<div class="tabla-cell"><strong>{{$t('recordsTable.tabRecordsCharger')}}</strong></div>
<div class="tabla-cell"><strong>{{$t('recordsTable.tabRecordsEnergy')}}</strong></div>
<div class="tabla-cell"><strong>CO2</strong></div>
</div>
<!-- TABLA HEADER -->

<!-- TABLA LISTADO -->
<div class="tabla-tabs-listado">
  <!-- tabla row -->
  <div class="tabla-row"
    test-data="dataRecord" v-for="sRec in formatedRecords" :key="sRec" :id="sRec.sDay">
  <div class="tabla-cell">{{sRec.startStr}}</div>
  <div class="tabla-cell">{{sRec.endStr}}</div>
  <div class="tabla-cell">{{sRec.deviceName}}</div>
  <div class="tabla-cell">{{$n(sRec.energy/1000, 'decimal')}} kWh</div>
  <div class="tabla-cell">{{$n((sRec.energy/1000)*1.16, 'decimal')}} kg</div>
  </div>
</div>

</div>
</div>
<!-- /TAB RECARGAS -->

<!-- TAB CARGADORES -->
<div id="tab2" v-show="tab==='chargers'">
<div class="tab-set">

<div class="tab-inactiva" test-data="recordsTab2"
@click.prevent="tab='records'">{{$t('recordsTable.tabRecords')}}</div>
<div class="tab-activa" test-data="devicesTab2"
  @click.prevent="tab='chargers'">{{$t('recordsTable.tabDevices')}}</div>
<div class="tab-inactiva" test-data="usersTab2"
  @click.prevent="tab='users'">{{$t('recordsTable.tabUsers')}}</div>

</div>
<div class="tabla-tabs">

<!-- TABLA HEADER -->
<div class="tabla-row-header">
<div class="tabla-cell"><strong>{{$t('recordsTable.tabDevicesName')}}</strong></div>
<div class="tabla-cell"><strong>{{$t('recordsTable.tabDevicesId')}}</strong></div>
<div class="tabla-cell"><strong>{{$t('recordsTable.tabDevicesEnergy')}}</strong></div>
<div class="tabla-cell"><strong>CO2</strong></div>
</div>
<!-- TABLA HEADER -->

<!-- TABLA LISTADO -->
<div class="tabla-tabs-listado">
  <!-- tabla row -->
  <div class="tabla-row" test-data="dataDevice" v-for="cRec in recsByCharger" :key="cRec">
  <div class="tabla-cell">{{cRec.deviceName}}</div>
  <div class="tabla-cell">{{cRec.ident}}</div>
  <div class="tabla-cell">{{$n(cRec.energy/1000, 'decimal')}} kWh</div>
  <div class="tabla-cell">{{$n((cRec.energy/1000)*1.16, 'decimal')}} kg</div>
  </div>
</div>

</div>
</div>
<!-- /TAB CARGADORES -->

<!-- TAB USUARIOS -->
<div id="tab3" v-show="tab==='users'">
<div class="tab-set">

<div class="tab-inactiva" test-data="recordsTab3"
  @click.prevent="tab='records'">{{$t('recordsTable.tabRecords')}}</div>
<div class="tab-inactiva" test-data="devicesTab3"
  @click.prevent="tab='chargers'">{{$t('recordsTable.tabDevices')}}</div>
<div class="tab-activa" test-data="usersTab3"
 @click.prevent="tab='users'">{{$t('recordsTable.tabUsers')}}</div>

</div>
<div class="tabla-tabs" >

<!-- TABLA HEADER -->
<div class="tabla-row-header">
<div class="tabla-cell"><strong>{{$t('recordsTable.tabUsersName')}}</strong></div>
<div class="tabla-cell"><strong>{{$t('recordsTable.tabUsersEnergy')}}</strong></div>
<div class="tabla-cell"><strong>CO2</strong></div>
</div>
<!-- TABLA HEADER -->

<!-- TABLA LISTADO -->
<div class="tabla-tabs-listado">
  <!-- tabla row -->
  <div class="tabla-row"  test-data="dataUser" v-for="uRec in recsByUser" :key="uRec.userID">
  <div class="tabla-cell">{{uRec.userName}}</div>
  <div class="tabla-cell">{{$n(uRec.energy/1000, 'decimal')}} kWh</div>
  <div class="tabla-cell">{{$n((uRec.energy/1000)*1.16, 'decimal')}} kg</div>
  </div>
</div>

</div>
</div>

<!-- /TAB USUARIOS -->

<!-- ------------------- /TABS ------------------- -->
</div>
</div>
<!-- ------------------- /PANEL-ESTADISTICAS ------------------- -->
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import * as ExcelJS from 'exceljs';

// import { Circle2 } from 'vue-loading-spinner';

export default {
  name: 'RecordsTable',
  // components: { Circle2 },
  props: {
    records: {
      type: Array,
      required: true,
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
    selectedDevices: {
      type: Array,
      required: true,
    },
    selectedUsers: {
      type: Array,
      required: true,
    },
    devices: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.adjustGraficoGrid);
  },
  updated() {
    this.adjustGraficoGrid();
  },
  computed: {
    maxScaleY() {
      return this.maxEnergy / 1000;
    },
    maxScaleYMonth() {
      return this.maxEnergyMonth / 1000;
    },
    recsByDayView() {
      let energy;

      const dates = [];
      const cDate = new Date(this.dateFrom);
      const cDateTo = new Date(this.dateTo);
      if (cDate.getFullYear() > 2010
          && cDateTo.getFullYear() > 2010
          && cDateTo.getFullYear() < (new Date().getFullYear() + 1)
          && cDate.getFullYear() < (new Date().getFullYear() + 1)) {
        let cDateStr;
        let cMonthStr;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.maxEnergy = 0;
        while (cDate <= cDateTo) {
          cDateStr = moment(cDate).format('YYYY-MM-DD');
          cMonthStr = moment(cDate).format('YYYY-MM');
          // eslint-disable-next-line no-loop-func
          const d = this.recsByDate.find((o) => o.sDay === cDateStr);
          if (d) {
            energy = d.energy;
          } else energy = 0;
          const dayNum = cDate.getDate();
          const monthNum = cDate.getMonth();
          const energyScaled = (energy * 10) / (this.maxEnergy);

          dates.push({
            day: cDateStr, cMonthStr, energy, dayNum, energyScaled, monthNum,
          });
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          if (energy >= this.maxEnergy) this.maxEnergy = energy;
          cDate.setDate(cDate.getDate() + 1);
          if (cDate.getDate() === 1 && cDate.getMonth() === 0) {
            dates.push({
              day: 'NEWYEAR', cMonthStr: `NEWYEAR${cDate.getFullYear()}`, energy: 0, dayNum: 1, energyScaled: 0, monthNum: cDate.getMonth(),
            });
          }
          if (cDate.getDate() === 1) {
            dates.push({
              day: 'NEWMONTH', cMonthStr: moment(cDate).format('YYYY-MM'), energy: 0, dayNum: 1, energyScaled: 0, monthNum: cDate.getMonth(),
            });
          }
        }
      }
      return dates;
    },
    recsByMonth() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.maxEnergyMonth = 0;
      return _(this.recsByDayView)
        .groupBy('cMonthStr')
        .map((g, cMonthStr) => {
          const energy = _.sumBy(g, 'energy');
          const { monthNum } = g[0];
          const energyScaled = energy / 1;
          if (energy >= this.maxEnergyMonth) this.maxEnergyMonth = energy;
          return ({
            cMonthStr,
            energy,
            monthNum,
            energyScaled,
          });
        })
        .values()
        .value();
    },
    recsByDate() {
      return _(this.records)
        .groupBy('sDay')
        .map((g, sDay) => {
          const energy = _.sumBy(g, 'energy');
          const { dayNum } = g[0];
          return ({
            sDay,
            energy,
            dayNum,
          });
        })
        .values()
        .value();
    },
    recsByUser() {
      return _(this.records)
        .groupBy('userID')
        .map((g, userID) => {
          const energy = _.sumBy(g, 'energy');
          const { userName } = g[0];
          return ({
            userID,
            energy,
            userName,
          });
        })
        .values()
        .value();
    },
    recsByCharger() {
      return _(this.records)
        .groupBy('deviceID')
        .map((g, deviceID) => {
          const energy = _.sumBy(g, 'energy');
          const { deviceName } = g[0];
          const { ident } = g[0];
          return ({
            deviceID,
            energy,
            deviceName,
            ident,
          });
        })
        .values()
        .value();
    },
    recsByGroupName() {
      return _(this.records)
        .groupBy('groupName')
        .map((g, groupName) => {
          const energy = _.sumBy(g, 'energy');
          return ({
            groupName,
            energy,
          });
        })
        .values()
        .value();
    },
    totalEnergy() {
      return _.sumBy(this.records, 'energy');
    },
    csvData() { // Records by date
      return this.records.map((item) => ({
        [`${this.$t('recordsTable.exportColumnStart')}`]: moment(item.start).format('YYYY-MM-DD HH:mm:ss'),
        [`${this.$t('recordsTable.exportColumnEnd')}`]: moment(item.end).format('YYYY-MM-DD HH:mm:ss'),
        [`${this.$t('recordsTable.exportColumnGroupName')}`]: item.groupName,
        [`${this.$t('recordsTable.exportColumnCharger')}`]: item.deviceName,
        [`${this.$t('recordsTable.exportColumnDeviceId')}`]: item.ident,
        [`${this.$t('recordsTable.exportColumnUserName')}`]: item.userName,
        [`${this.$t('recordsTable.exportColumnEnergy')}`]: item.energy / 1000,
        [`${this.$t('recordsTable.exportColumnCO2')}`]: (item.energy / 1000) * 1.16,
      }));
    },
    csvData2() { // Records by charger
      return this.recsByCharger.map((item) => ({
        [`${this.$t('recordsTable.exportColumnCharger')}`]: item.deviceName,
        [`${this.$t('recordsTable.exportColumnDeviceId')}`]: item.ident,
        [`${this.$t('recordsTable.exportColumnEnergy')}`]: item.energy / 1000,
        [`${this.$t('recordsTable.exportColumnCO2')}`]: (item.energy / 1000) * 1.16,
      }));
    },
    csvData3() { // Records by user
      return this.recsByUser.map((item) => ({
        [`${this.$t('recordsTable.exportColumnUserName')}`]: item.userName,
        [`${this.$t('recordsTable.exportColumnEnergy')}`]: item.energy / 1000,
        [`${this.$t('recordsTable.exportColumnCO2')}`]: (item.energy / 1000) * 1.16,
      }));
    },
    csvData4() { // Records by group
      return this.recsByGroupName.map((item) => ({
        [`${this.$t('recordsTable.exportColumnGroupName')}`]: item.groupName,
        [`${this.$t('recordsTable.exportColumnEnergy')}`]: item.energy / 1000,
        [`${this.$t('recordsTable.exportColumnCO2')}`]: (item.energy / 1000) * 1.16,
      }));
    },
    formatedRecords() {
      return this.records.map((item) => ({
        ...item,
        startStr: moment(item.start).format('YYYY-MM-DD HH:mm'),
        endStr: moment(item.end).format('YYYY-MM-DD HH:mm'),
      }));
    },
  },
  data() {
    return {
      tab: 'records',
      graphTab: 'perDay',
      maxEnergy: 1,
      maxEnergyMonth: 1,
      exporting: false,
    };
  },
  methods: {
    isActive(filter) {
      return (filter === this.graphTab);
    },
    setGraphPerDay() {
      this.graphTab = 'perDay';
    },
    setGraphPerMonth() {
      this.graphTab = 'perMonth';
    },

    scrollToDay(id) {
      if (id !== null) {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    },
    adjustGraficoGrid() {
      if (this.isActive('perDay')) {
        // reajustar grafico per DAY (testigo)
        const altura = document.getElementById('testigoaltura').clientHeight;
        const anchura = `${document.getElementById('graficoday').clientWidth}px`;

        document.getElementById('grid2').style.top = `${altura}px`;
        document.getElementById('grid3').style.top = `${altura + altura}px`;
        document.getElementById('grid4').style.top = `${altura + altura + altura}px`;
        document.getElementById('tramoescala1').style.height = `${altura}px`;
        document.getElementById('tramoescala2').style.height = `${altura}px`;
        document.getElementById('tramoescala3').style.height = `${altura}px`;
        document.getElementById('tramoescala4').style.height = `${altura}px`;
        document.getElementById('grid1').style.width = anchura;
        document.getElementById('grid2').style.width = anchura;
        document.getElementById('grid3').style.width = anchura;
        document.getElementById('grid4').style.width = anchura;
      } else {
        // reajustar grafico per DAY (testigo)
        // eslint-disable-next-line no-lonely-if
        if (document.getElementById('graficomonth') !== null) {
          const alturames = document.getElementById('testigoalturames').clientHeight;
          const anchurames = `${document.getElementById('graficomonth').clientWidth}px`;

          document.getElementById('gridmes2').style.top = `${alturames}px`;
          document.getElementById('gridmes3').style.top = `${alturames + alturames}px`;
          document.getElementById('gridmes4').style.top = `${alturames + alturames + alturames}px`;
          document.getElementById('tramoescalames1').style.height = `${alturames}px`;
          document.getElementById('tramoescalames2').style.height = `${alturames}px`;
          document.getElementById('tramoescalames3').style.height = `${alturames}px`;
          document.getElementById('tramoescalames4').style.height = `${alturames}px`;
          document.getElementById('gridmes1').style.width = anchurames;
          document.getElementById('gridmes2').style.width = anchurames;
          document.getElementById('gridmes3').style.width = anchurames;
          document.getElementById('gridmes4').style.width = anchurames;
        }
      }
    },
    // arrData: records by date
    // arrData2: records by charger
    // arrData3: records by user
    // arrData4: records by group
    exportToExcel(arrData, arrData2, arrData3, arrData4) {
      const workbook = new ExcelJS.Workbook();

      // Function to add a sheet with headers
      const addSheetWithHeaders = (data, columns, sheetName) => {
        const worksheet = workbook.addWorksheet(sheetName);
        const headerRow = worksheet.addRow(columns);
        worksheet.properties.defaultColWidth = 20; // Ancho de las celdas

        // Formato para las celdas de cabecera
        headerRow.eachCell((cell) => {
          // eslint-disable-next-line no-param-reassign
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            bgColor: { argb: '062A78' }, // Color de fondo azul
            fgColor: { argb: '062A78' }, // Color de fuente blanco
          };
          // eslint-disable-next-line no-param-reassign
          cell.font = {
            color: { argb: 'FFFFFF' }, // Color de fuente blanco
            bold: true,
          };
          // eslint-disable-next-line no-param-reassign
          cell.alignment = { horizontal: 'center' }; // Alinear el texto al centro
          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }; // Agregar bordes finos a todas las celdas
        });

        // Formato para las celdas de datos
        data.forEach((row) => {
          const dataRow = worksheet.addRow(row);
          dataRow.eachCell((cell) => {
            // eslint-disable-next-line no-param-reassign
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            }; // Agregar bordes finos a todas las celdas
          });
        });
      };
      // Add a sheet with the general information (1st sheet)
      const addGeneralSheet = (filter, chargers, users, summary) => {
        const worksheet = workbook.addWorksheet(`${this.$t('recordsTable.sheetTitle0')}`);

        const formatGenericRow = (myWorksheet, rowNumber, value1, value2) => {
          const row = myWorksheet.getRow(rowNumber);

          // Formato para la primera celda
          const cell1 = row.getCell(1);
          cell1.value = value1;
          cell1.font = {
            color: { argb: 'FFFFFF' }, // Color de fuente blanco
            bold: true,
          };
          cell1.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '062A78' },
          };
          cell1.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          // eslint-disable-next-line no-param-reassign
          myWorksheet.getColumn(1).width = 30;

          // Formato para la segunda celda
          const cell2 = row.getCell(2);
          cell2.value = value2;
          cell2.font = {
            bold: false,
          };
          cell2.fill = { type: 'pattern', pattern: 'none' }; // Sin color de fondo
          cell2.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          // eslint-disable-next-line no-param-reassign
          myWorksheet.getColumn(2).width = 20;
        };

        formatGenericRow(worksheet, 1, this.$t('recordsTable.sheetGeneralDateFrom'), new Date(filter.from));
        formatGenericRow(worksheet, 2, this.$t('recordsTable.sheetGeneralDateTo'), new Date(filter.to));
        formatGenericRow(worksheet, 3, this.$t('recordsTable.sheetGeneralTotalRecords'), summary.totalRecords);
        formatGenericRow(worksheet, 4, this.$t('recordsTable.sheetGeneralTotalEnergy'), summary.totalEnergy / 1000);
        formatGenericRow(worksheet, 5, this.$t('recordsTable.sheetGeneralTotalCO2'), (summary.totalEnergy / 1000) * 1.16);

        // create an array with the devices from this.devices selected in this.selectedDevices
        // save only serial number, name, and type
        const mySelectedDevices = this.devices
          .filter((device) => this.selectedDevices.includes(device.key))
          .map((device) => ({
            serialNumber: device.ident,
            name: device.name,
            type: device.type,
          }));

        // do the same with users, getting email and name+surname
        const mySelectedUsers = this.users
          .filter((user) => this.selectedUsers.includes(user.key))
          .map((user) => ({
            email: user.email,
            name: `${user.name} ${user.surname}`,
          }));

        // Formato para la tabla de cargadores
        const chargersColumns = [
          'recordsTable.sheetChargersTableSerialNumber',
          'recordsTable.sheetChargersTableName',
          'recordsTable.sheetChargersTableType',
        ];

        // Formato para la tabla de usuarios
        const usersColumns = [
          'recordsTable.sheetUsersTableName',
          'recordsTable.sheetUsersTableEmail',
        ];

        // Add an empty row as a separator
        worksheet.addRow([]);

        // Add headers for chargers table
        const chargersHeaderRow = worksheet.addRow(
          chargersColumns.map((header) => this.$t(header)),
        );
        chargersHeaderRow.font = { bold: true };

        chargersHeaderRow.eachCell({ includeEmpty: true }, (cell, index) => {
          // eslint-disable-next-line no-param-reassign
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '062A78' }, // Blue background color
          };
          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          // eslint-disable-next-line no-param-reassign
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' }, // Color de fuente blanco
          };
          // eslint-disable-next-line no-param-reassign
          cell.alignment = { horizontal: 'center' }; // Alinear el texto al centro
          worksheet.getColumn(index).width = 30;
        });

        // Add data for chargers table
        mySelectedDevices.forEach((device) => {
          const datarow = worksheet.addRow([device.serialNumber, device.name, device.type]);
          datarow.eachCell({ includeEmpty: true }, (cell) => {
            // eslint-disable-next-line no-param-reassign
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
        });

        // Add an empty row as a separator
        worksheet.addRow([]);

        // Add headers for users table
        const usersHeaderRow = worksheet.addRow(usersColumns.map((header) => this.$t(header)));
        usersHeaderRow.font = { bold: true };
        usersHeaderRow.eachCell({ includeEmpty: true }, (cell, index) => {
          // eslint-disable-next-line no-param-reassign
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '062A78' }, // Blue background color
          };
          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          // eslint-disable-next-line no-param-reassign
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' }, // Color de fuente blanco
          };
          // eslint-disable-next-line no-param-reassign
          cell.alignment = { horizontal: 'center' }; // Alinear el texto al centro
          worksheet.getColumn(index).width = 25;
        });

        // Add data for users table
        mySelectedUsers.forEach((user) => {
          const datarow = worksheet.addRow([user.name, user.email]);
          datarow.eachCell({ includeEmpty: true }, (cell) => {
            // eslint-disable-next-line no-param-reassign
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });
        });
      };

      const originalColumns = Object.keys(arrData[0]);
      const groupedByCargadorColumns = Object.keys(arrData2[0]);
      const groupedByUsuarioColumns = Object.keys(arrData3[0]);
      const groupedByGrupoColumns = Object.keys(arrData4[0]);

      const originalData = arrData.map((obj) => Object.values(obj));
      const groupedByCargadorData = arrData2.map((obj) => Object.values(obj));
      const groupedByUsuarioData = arrData3.map((obj) => Object.values(obj));
      const groupedByGrupoData = arrData4.map((obj) => Object.values(obj));

      const summary = {
        totalRecords: this.records.length,
        totalEnergy: this.totalEnergy,
        totalCO2: this.totalEnergy * 1.16,
      };

      const filter = {
        from: this.dateFrom,
        to: this.dateTo,
      };
      console.log(filter);
      addGeneralSheet(filter, this.selectedDevices, this.selectedUsers, summary);

      addSheetWithHeaders(originalData, originalColumns, this.$i18n.t('recordsTable.sheetTitle1'));
      addSheetWithHeaders(groupedByCargadorData, groupedByCargadorColumns, this.$i18n.t('recordsTable.sheetTitle2'));
      addSheetWithHeaders(groupedByUsuarioData, groupedByUsuarioColumns, this.$i18n.t('recordsTable.sheetTitle3'));
      addSheetWithHeaders(groupedByGrupoData, groupedByGrupoColumns, this.$i18n.t('recordsTable.sheetTitle4'));

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // Compond file name usinf current datetime
        const date = new Date();

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hour = date.getHours();
        const minutes = date.getMinutes();

        const filename = `data_${day}-${month}-${year}_${hour}-${minutes}.xlsx`;

        if (navigator.msSaveBlob) {
          // Para IE11
          navigator.msSaveBlob(blob, filename);
        } else {
          // Para navegadores modernos
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      });
    },

  },
};
</script>
