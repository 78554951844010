import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Reset from '../views/Reset.vue';
import Register from '../views/Register.vue';
import RequestAccountDeletion from '../views/RequestAccountDeletion.vue';
import ConfirmAccountDeletion from '../views/ConfirmAccountDeletion.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (!store.state.auth.userLoggedIn) {
        next();
      } else {
        const redirectPath = to.query.redirect || '/';
        next({ path: redirectPath });
      }
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/requestAccountDeletion',
    name: 'requestAccountDeletion',
    component: RequestAccountDeletion,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/confirmAccountDeletion',
    name: 'confirmAccountDeletion',
    component: ConfirmAccountDeletion,
    meta: {
      requiresAuth: false,
    },
    // query string parameters
    props: (route) => ({
      email: route.query.email,
      locale: route.query.locale,
      token: route.query.token,
    }),
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'home' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.userLoggedIn) {
      next();
    } else {
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
