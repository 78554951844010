<template>
  <!-- ---------------------------- -->
  <!-- ---------- FOOTER ---------- -->
  <div class="footer">

      <div class="footerMain">&copy; Veltium Smart Chargers S.L. </div>

      <div class="footerLinks">
      <a href="https://www.veltium.com/aviso-legal/"
        target="_blank">{{$t('footer.legal')}}</a>
      &nbsp;|&nbsp; <a href="https://www.veltium.com/politica-de-privacidad/" target="_blank">{{$t('footer.privacy')}}</a>
      &nbsp;|&nbsp; <a href="https://www.veltium.com/cookies/" target="_blank">Cookies</a>
      &nbsp;|&nbsp; v{{version}}
      </div>

  </div>
  <!-- ---------- /FOOTER ---------- -->
  <!-- ----------------------------- -->
  </template>
<script>
// importar versión del paquete para mostrarla en el footer
import { version } from '../../package.json';

export default {
  data() {
    return {
      version,
    };
  },
};
// export default {
//   name: 'Footer',
//   methods: {
//     // hacer original
//     original() {
//       document.getElementById('canvas').style.width = '1440px';
//       document.getElementById('canvas').style.height = '957px';
//       document.getElementById('canvas').style.margin = 'auto';
//     },
//     // hacer windowed
//     windowed() {
//       document.getElementById('canvas').style.width = 'auto';
//       document.getElementById('canvas').style.height = '80vh';
//       document.getElementById('canvas').style.margin = '10vh';
//     },
//     // hacer fullwidth
//     fullwidth() {
//       document.getElementById('canvas').style.width = '100vw';
//       document.getElementById('canvas').style.height = '100vh';
//       document.getElementById('canvas').style.margin = '0px';
//     },
//   },
// };

</script>
