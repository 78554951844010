<template>
  <!-- ==================== CONTENIDOS ==================== -->
  <div class="login-contenido">

  <div class="mainFrame" id="loginMainFrame">

      <img id="logoVeltium" src="assets/img/logo-veltium.svg" />

      <!-- Reset message -->

      <div v-if="reset_show_alert" :class="warning">
          <div id="title" class="warning-title">{{ reset_alert_title }}</div>
          <div id="message" class="warning-text">{{ reset_alert_msg }}</div>
      </div>

      <vee-form :validation-schema="resetSchema"
      @submit="reset" v-show="!mail_sent"
      v-slot="{errors}">

      <!-- email -->
      <vee-field type="text" name="email" class="login-input" maxlength="32"
          :placeholder="$t('reset.enterMail')"
          test-data="emailInput"
          :class="{'login-input-warning ': hasErrors(errors, 'email')}"/>
      <div class="warning"><ErrorMessage name="email" /></div>

      <!-- submit -->
      <input type="submit" class="login-button" maxlength="32"
          :disabled="reset_in_submission"
          test-data="sendButton"
          :value="$t('reset.send')"/>

      </vee-form>

      <!-- submit -->
      <input type="submit" class="login-button" maxlength="32"
          :disabled="reset_in_submission" v-show="mail_sent" @click="$router.push('login')"
          test-data="continueButton"
          :value="$t('reset.continue')"/>

      <!-- Back to Login -->
      <div class="forgot" v-show="!mail_sent">
      <router-link to="/login">{{$t('reset.back')}}</router-link>
      </div>

  </div>

  </div>
  <!-- ==================== /CONTENIDOS ==================== -->
  </template>
<script>
import { auth } from '@/includes/firebase';
import '../assets/veltium-login.css';

export default {
  name: 'reset',
  data() {
    return {
      resetSchema: {
        email: 'required|email',
      },
      reset_in_submission: false,
      reset_show_alert: false,
      reset_alert_variant: 'bg-white-500',
      reset_alert_msg: this.$t('reset.wait'),
      mail_sent: false,
    };
  },
  methods: {
    async reset(values) {
      this.reset_show_alert = true;
      this.reset_in_submission = true;
      this.reset_alert_variant = 'bg-white-500';
      this.reset_alert_title = '';
      this.reset_alert_msg = this.$t('reset.wait');

      try {
        // TODO: set the user language based on the the default for sending the emails
        await auth.sendPasswordResetEmail(values.email);
      } catch (error) {
        this.reset_in_submission = false;
        this.reset_alert_title = this.$t('reset.checkInbox');
        this.reset_alert_msg = this.$t('reset.success');
        this.mail_sent = true;
        return;
      }
      this.reset_in_submission = false;
      this.reset_alert_title = this.$t('reset.checkInbox');
      this.reset_alert_msg = this.$t('reset.success');
      this.reset_show_alert = true;
      this.mail_sent = true;
    },
    hasErrors(errors, field) {
      return ((errors === undefined) ? false : (field in errors));
    },
  },
};
</script>
