import moment from 'moment';
import _ from 'lodash';

export default {
  getModel(partNumber) {
    return partNumber.substr(1, 1);
  },
  getFront(partNumber) {
    return partNumber.substr(7, 1);
  },
  getCaratula(partNumber) {
    return partNumber.substr(5, 1);
  },
  getPartNuberVersion(partNumber) {
    return partNumber.substr(10, 1);
  },
  getDeviceTypeV1(partNumber) {
    const model = this.getModel(partNumber);
    const front = this.getFront(partNumber);
    switch (model) {
      case 'Z':
        if (['1', '2', '3', '4', '7', '8', '9'].includes(front)) {
          return 'LITE Zero';
        }
        if (['5', '6'].includes(front)) {
          return 'NEO';
        }
        break;
      case 'U':
        if (['1', '2', '3', '4', '7', '8', '9'].includes(front)) {
          return 'LITE Uno';
        }
        if (['5', '6'].includes(front)) {
          return 'NEO Pro';
        }
        if (['7'].includes(front)) {
          return 'Lite Uno';
        }
        break;
      case 'K':
        if (['1', '2', '3', '4', '7', '8', '9'].includes(front)) {
          return 'LITE Kubo';
        }
        if (['5', '6'].includes(front)) {
          return 'NEO Pro';
        }
        break;
      default:
    }
    return '';
  },
  getDeviceTypeV2(partNumber) {
    const model = this.getModel(partNumber);
    const caratula = this.getCaratula(partNumber);
    switch (model) {
      case 'Z':
        if (['I'].includes(caratula)) {
          return 'NEO';
        }
        return 'LITE Zero';
      case 'U':
        if (['I'].includes(caratula)) {
          return 'NEO Pro';
        }
        return 'Lite Uno';
      case 'K':
        if (['I'].includes(caratula)) {
          return 'NEO Pro';
        }
        return 'Lite Kubo';
      default:
    }
    return '';
  },
  getImageNameV1(partNumber) {
    switch (this.getFront(partNumber)) {
      case '1':
        return 'cargador-negro';
      case '2':
        return 'cargador-blanco';
      case '3':
        return 'cargador-naranja';
      case '4':
        return 'cargador-azul';
      case '5':
        return 'cargador-ingeteam-rojo';
      case '6':
        return 'cargador-ingeteam-blanco';
      case '7':
        return 'cargador-naturgy-azul';
      case '8':
        return 'cargador-emovili-blanco';
      case '9':
        return 'cargador-emovili-negro';
      default:
    }
    return 'cargador-blanco';
  },
  getImageNameV2(partNumber) {
    switch (this.getFront(partNumber)) {
      case '1':
        return 'cargador-negro';
      case '2':
        return 'cargador-blanco';
      case '3':
        return 'cargador-naranja';
      case '4':
        return 'cargador-azul';
      case '5':
        return 'cargador-ingeteam-rojo';
      case '7':
        return 'cargador-naturgy-azul';
      default:
        return 'cargador-blanco';
    }
  },
  getImageName(partNumber) {
    switch (this.getPartNuberVersion(partNumber)) {
      case '0':
        return this.getImageNameV1(partNumber);
      case '1':
        return this.getImageNameV2(partNumber);
      default:
        return this.getImageNameV2(partNumber);
    }
  },
  getDeviceType(partNumber) {
    switch (this.getPartNuberVersion(partNumber)) {
      case '0':
        return this.getDeviceTypeV1(partNumber);
      case '1':
        return this.getDeviceTypeV2(partNumber);
      default:
        return this.getDeviceTypeV2(partNumber);
    }
  },
  // eslint-disable-next-line no-unused-vars
  formatCDR(recordDB, locale) {
    const sCon = new Date(recordDB.con * 1000);
    const sDis = new Date(recordDB.dis * 1000);
    const sSta = new Date(recordDB.sta * 1000);
    const sDay = moment(sDis).format('YYYY-MM-DD');
    // const options = {
    //   year: 'numeric', month: 'short', day: 'numeric',
    //    hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false,
    // };
    const start = sCon; // sCon.toLocaleString(locale);
    const end = sDis; // sDis.toLocaleString(locale);
    const dayNum = new Date(sDis).getDate();
    const rawData = recordDB.act;
    const buffer = Buffer.from(rawData, 'base64');
    const bufString = buffer.toString('hex');
    const eData = this.hexStringToByteArray(bufString);

    const record = {
      sCon,
      sDis,
      sSta,
      sDay,
      dayNum,
      ...recordDB,
      start,
      end,
      energy: _.sum(eData),
    };
    return record;
  },
  hexStringToByteArray(hexString) {
    if (hexString.length % 2 !== 0) {
      throw Error('Invalid string');
    }
    const numBytes = hexString.length / 4;
    const byteArray = new Uint16Array(numBytes);
    for (let i = 0; i < numBytes; i += 1) {
      byteArray[i] = parseInt(hexString.substr(i * 4, 4), 16);
    }
    return byteArray;
  },
  snapshotToArray(snapshot) {
    const returnArr = [];

    snapshot.forEach((document) => {
      const item = {
        ...document.val(),
        key: document.key,
      };

      returnArr.push(item);
    });

    return returnArr;
  },
};
