<template>
  <!-- ----- HEADER ----- -->
  <div class="header">
      <img id="logo-veltium" src="assets/img/logo-veltium.svg" alt="VELTIUM - Keep it simple">
      <img id="logo-AreaCliente" src="assets/img/AreaCliente.svg" alt="AreaCliente">
      <div class="menues">

          <!-- locale-switcher -->
          <app-locale-switcher :selectedLocale="$i18n.locale"
              test-data="localeSwitcher"
              :locales="supportedLocales"
              @locale-changed="changeLocale" />
          <!-- /locale-switcher -->

          <!-- usuMenu -->
          <div class="usuMenu" @mouseleave="userMenuMouseleave">

              <div class="usuMenuHeader" test-data="userMenu"
              @click.prevent="toggleModalClass">
                  <img id="usericon" src="assets/img/ico-user.svg">
                  {{userLogged.name}}
                  {{userLogged.email}}
                  <img src="/assets/img/ico-expandMenu.svg">
              </div><!-- /usuMenuHeader -->

              <!-- User menu dropdown ( user-menu-visible | user-menu-invisible) -->
              <div id="user-menu-visible"  :class="modalClass"
                v-show="modalClass=='user-menu-visible'">
                  <ul>
                  <li>{{userLogged.name}}</li>
                  <li>{{userLogged.email}}</li>
                  <li><a href="#" @click.prevent="signout">{{$t('header.logout')}}</a>
                  </li>
                  </ul>
              </div><!-- /User menu dropdown -->

          <div class="usuMenuContent">
          </div><!-- /usuMenuContent -->

          </div>
          <!-- /usuMenu -->

      </div><!-- /menues -->
  </div>
  <!-- ----- /HEADER ----- -->
  </template>

<script>
import { mapMutations, mapState } from 'vuex';
import AppLocaleSwitcher from '@/components/LocaleSwitcher.vue';
import { auth } from '@/includes/firebase';

export default {
  name: 'Header',
  components: { AppLocaleSwitcher },
  data() {
    return {
      modalClass: 'user-menu-invisible',
      supportedLocales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
    };
  },
  computed: {
    ...mapState({
      userLoggedIn: (state) => state.auth.userLoggedIn,
      userLogged: (state) => state.auth.userLogged,
    }),
  },
  created() {
    this.$store.dispatch('getUserDetails', auth.currentUser);
  },
  mounted() {

  },
  methods: {
    ...mapMutations(['toggleAuthModal']),
    signout() {
      this.$store.dispatch('signout');
      this.toggleModalClass();

      this.$router.push({ name: 'login' });
    },
    toggleModalClass() {
      if (this.modalClass === 'user-menu-invisible') {
        this.modalClass = 'user-menu-visible';
      } else {
        this.modalClass = 'user-menu-invisible';
      }
    },
    userMenuMouseleave() {
      this.modalClass = 'user-menu-invisible';
    },
    changeLocale(e) {
      this.$i18n.locale = e;
    },
  },
};

</script>
