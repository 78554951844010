<template>
  <div :class="{ 'modal': true, 'none': !isOpen }">
    <div class="bubble">
      <div class="title-row">
        <div class="icon"><img alt="" :src="iconSrc"></div>
        <div class="title">{{ title }}</div>
      </div>
      <div class="text-row">
        <p v-html="formattedText"></p>
      </div>
      <div class="bottons-row" v-if="confirmButtonText!==''">
        <input @click="confirmAction" type="button"
          :class="confirmButtonClass" :value="confirmButtonText">
        <input @click="cancelAction" type="button" class="button-cancelar"
        v-if="showCancelButton"
        :value="$t('modal.cancel')">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    title: String,
    text: Array,
    confirmButtonClass: String,
    confirmButtonText: String,
    showCancelButton: Boolean,
  },
  computed: {
    formattedText() {
      return this.text;
    },
    iconSrc() {
      // dependiendo del class del botón, se muestra un icono u otro
      if (this.confirmButtonClass === 'button-warn') {
        return 'assets/img/ico-warn.svg';
      }
      if (this.confirmButtonClass === 'button-error') {
        return 'assets/img/ico-error.svg';
      }
      return 'assets/img/ico-warn.svg';
    },
  },
  methods: {
    confirmAction() {
      this.$emit('confirm-action');
    },
    cancelAction() {
      this.$emit('cancel-action');
    },
  },

};
</script>
