import { createI18n } from 'vue-i18n';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `
 * @intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  numberFormats: {
    en: {
      decimal: {
        style: 'decimal',
        locale: 'EN',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        useGrouping: true,
      },
    },
    es: {
      decimal: {
        style: 'decimal',
        locale: 'ES',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        useGrouping: true,
      },
    },
    fr: {
      decimal: {
        style: 'decimal',
        locale: 'ES',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        useGrouping: true,
      },
    },
    it: {
      decimal: {
        style: 'decimal',
        locale: 'ES',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        useGrouping: true,
      },
    },
    pt: {
      decimal: {
        style: 'decimal',
        locale: 'ES',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
        useGrouping: true,
      },
    },
  },
});
