import {
  Form as VeeForm, Field as VeeField, defineRule, ErrorMessage, configure,
} from 'vee-validate';
import {
  required, min, max, alpha_spaces as alphaSpaces, email, min_value as minValue,
  max_value as maxValue, confirmed, not_one_of as excluded,
} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

export default {
  install(app) {
    app.component('VeeForm', VeeForm);
    app.component('VeeField', VeeField);
    app.component('ErrorMessage', ErrorMessage);

    defineRule('required', required);
    defineRule('tos', required);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('alpha_spaces', alphaSpaces);
    defineRule('email', email);
    defineRule('min_value', minValue);
    defineRule('max_value', maxValue);
    defineRule('passwords_mismatch', confirmed);
    defineRule('country_excluded', excluded);

    configure({
      generateMessage: localize({
        es: {
          messages: {
            required: 'Introduce un {field} válido.',
            min: 'El campo {field} es muy corto.',
            max: 'El campo {field} es muy largo.',
            email: 'Debes introducir un mail válido.',

          },
        },
        en: {
          messages: {
            required: 'Please enter a valid {field}.',
            min: 'The filed {field} is too short.',
            max: 'The filed {field} is too long.',
            email: 'Please enter a valid email.',

          },
        },
        pt: {
          messages: {
            required: 'El campo {field} es obligatorio.',
            min: 'El cmapo {field} es muy corto.',
            max: 'El campo {field} es muy largo.',
            email: 'Debes introducir un mail válido.',

          },
        },
      }),
      classes: true,
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: false,
      validateOnModelUpdate: true,
    });
  },
};
