<template>
  <div class="canvas" id="canvas">
  <app-header v-if="userLoggedIn"/>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view/>
  <app-footer />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    AppHeader, AppFooter,
  },
  computed: {
    ...mapState({
      userLoggedIn: (state) => state.auth.userLoggedIn,
    }),
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.$i18n.locale = navigator.language.split('-')[0];
    this.$store.dispatch('init_login');
  },

};
</script>

<style>

</style>
