export default {
  "devicesTable": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargeurs"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "createSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un groupe"])},
    "addCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un chargeur"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un Groupe"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "serialNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de Série..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un Groupe"])},
    "groupNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du Groupe..."])},
    "undoGrouping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler le groupement"])},
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développer"])},
    "deleteCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le chargeur"])},
    "removeFromGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer du groupe"])},
    "deviceImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image du dispositif"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandé"])},
    "remindApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappeler l'Approbation"])},
    "addToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au Groupe"])},
    "confirmDeleteCharger": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'accès au chargeur"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Vous perdrez l'accès au chargeur</b>!<br/><br/>Pour le récupérer, vous devrez demander à être ajouté à nouveau."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
    },
    "confirmDeleteGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les chargeurs apparaissent désormais en dehors du groupe."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
    },
    "confirmAddCharger": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargeur existe déjà"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un chargeur avec le numéro de série dans votre liste."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un chargeur ?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une demande d'ajout du chargeur sera envoyée. Le chargeur sera en attente jusqu'à ce que la demande soit approuvée."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      },
      "notFound": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargeur non trouvé"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de série n'a pas été trouvé dans la base de données."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
      }
    },
    "confirmAddGroup": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe déjà existant"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un groupe dans votre liste avec ce nom."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une agrégation "])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nouvelle agrégation sera créée."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      }
    },
    "confirmSendReminder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rappel d'approbation"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un rappel sera envoyé pour approuver l'accès au chargeur."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
    },
    "confirmRemoveFromGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer du groupe. Chargeur : "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargeur apparaîtra en dehors du groupe."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
    },
    "confirmAddToGroup": {
      "noGroups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe créé."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez le bouton + pour créer un nouveau groupe."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      },
      "addToGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au groupe"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chargeur apparaîtra dans le groupe."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
      }
    },
    "searchingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche en cours.."])},
    "searchingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter pendant que nous vérifions les informations."])}
  },
  "footer": {
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])}
  },
  "header": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer session"])}
  },
  "home": {
    "1year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an"])},
    "30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 jours"])},
    "7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 jours"])},
    "chargeRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharges"])},
    "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du :"])},
    "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au :"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
    "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mois-ci"])},
    "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine"])},
    "thisYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette année"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard LITE"])},
    "tabAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisé"])},
    "tabSupervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superviseur"])},
    "tabAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])}
  },
  "login": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez votre e-mail"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
    "invalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations d’authentification incorrectes"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se souvenir de moi"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement réussi!"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter pendant que nous vérifions les informations."])}
  },
  "recordsTable": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
    "exportColumnCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargeur"])},
    "exportColumnDeviceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "exportColumnEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
    "exportColumnEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Énergie (Wh)"])},
    "exportColumnStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
    "exportColumnUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "exportColumnGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "exportColumnCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 (kg)"])},
    "filterPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par jour"])},
    "filterPerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par mois"])},
    "tabDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHARGEURS"])},
    "tabDevicesEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Énergie"])},
    "tabDevicesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tabDevicesName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "tabRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECHARGES"])},
    "tabRecordsCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargeurs"])},
    "tabRecordsEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
    "tabRecordsEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Énergie"])},
    "tabRecordsStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
    "tabUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UTILISATEURS"])},
    "tabUsersEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Énergie"])},
    "tabUsersName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharges et consommations"])},
    "sheetTitle0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
    "sheetTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrements"])},
    "sheetTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par chargeur"])},
    "sheetTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par utilisateur"])},
    "sheetTitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par groupe"])},
    "sheetGeneralDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De :"])},
    "sheetGeneralDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À :"])},
    "sheetGeneralTotalRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des enregistrements :"])},
    "sheetGeneralTotalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de l'énergie (KwH) :"])},
    "sheetGeneralTotalCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total CO2 (Kg) :"])},
    "sheetChargersTableSerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de série"])},
    "sheetChargersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "sheetChargersTableType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "sheetUsersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "sheetUsersTableEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "reset": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "checkInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre boite de réception"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduisez votre e-mail"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous avons envoyé des instructions pour rétablir votre mot de passe"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter pendant que nous vérifions les informations."])}
  },
  "usersTable": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])}
  },
  "modal": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
  },
  "request_account_deletion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le compte de l'application Veltium"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer votre compte ?"])},
    "message2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevrez un e-mail avec des instructions sur le processus de suppression."])},
    "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez patienter pendant que nous vérifions les informations."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "selectNewAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un nouvel administrateur pour le chargeur "])},
    "mailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande envoyée"])},
    "mailSentMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous avons envoyé un e-mail avec des instructions pour supprimer votre compte."])},
    "mailSentMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne le trouvez pas, vérifiez le dossier spam et assurez-vous d'avoir correctement saisi votre adresse e-mail."])},
    "mailSentMessage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous ne recevez toujours pas l'e-mail, veuillez nous contacter à l'adresse suivante : "])}
  },
  "confirm_account_deletion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm account deletion"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your account?"])},
    "message2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicking on the button below will delete your account."])},
    "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm deletion!!!"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! we are processing the request."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "selectNewAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a new administrator for the charger"])},
    "linkInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien est invalide ou a expiré."])}
  }
}