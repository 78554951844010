<template>

  <!-- usuMenu -->
  <div class="usuMenu" @mouseleave="langMenuMouseleave">

      <div class="usuMenuHeader lang-current" test-data="languageMenu"
      @click.prevent="toggleModalClassLang">
          <img src="assets/img/ico-globe.svg">
          {{getLocaleName(selectedLocale)}}
          <img src="/assets/img/ico-expandMenu.svg">
      </div><!-- /usuMenuHeader -->

      <!-- User menu dropdown ( user-menu-visible | user-menu-invisible) -->
      <div id="lang-menu" :class="modalClassLang" v-show="modalClassLang==='lang-menu-visible'">
          <ul>
          <li v-for="locale in locales" :key="locale"><a href="#" :test-data="locale"
      :class="[isCurrent(locale) ? 'lang-menu-active': 'lang-menu']"
      @click="switchLocale(locale)">{{getLocaleName(locale)}}</a>
          </li>
          </ul>
      </div><!-- /User menu dropdown -->

  <div class="usuMenuContent">
  </div><!-- /usuMenuContent -->

  </div>
  <!-- /usuMenu -->

  </template>
<script>
export default {
  name: 'LocaleSwitcher',
  emits: ['locale-changed'],
  props: {
    selectedLocale: {
      type: String,
      required: true,
    },
    locales: {
      type: Array,
      requiered: true,
    },
  },
  data() {
    return {
      modalClassLang: 'lang-menu-invisible',
      // locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      // locales: ['en', 'es'],
      localeNames: {
        es: 'Español',
        en: 'English',
        fr: 'Français',
        it: 'Italiano',
        pt: 'Português',
      },
    };
  },
  methods: {
    switchLocale(locale) {
      if (this.selectedLocale !== locale) {
        this.$emit('locale-changed', locale);
      }
    },
    getLocaleName(localeCode) {
      return this.localeNames[localeCode] ? this.localeNames[localeCode] : localeCode;
    },
    isCurrent(locale) {
      return (locale === this.selectedLocale);
    },
    toggleModalClassLang() {
      if (this.modalClassLang === 'lang-menu-invisible') {
        this.modalClassLang = 'lang-menu-visible';
      } else {
        this.modalClassLang = 'lang-menu-invisible';
      }
      const anchura = document.getElementById('lang-container').offsetWidth;
      document.getElementById('lang-menu').style.width = `${anchura}px`;
    },
    langMenuMouseleave() {
      this.modalClassLang = 'lang-menu-invisible';
      const anchura = document.getElementById('lang-container').offsetWidth;
      document.getElementById('lang-menu').style.width = `${anchura}px`;
    },
  },
};

</script>
