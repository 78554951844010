export default {
  "devicesTable": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricabatterie"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
    "createSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Gruppo"])},
    "addCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi caricatore"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Gruppo"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "serialNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di Serie..."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "createGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea Gruppo"])},
    "groupNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del Gruppo..."])},
    "undoGrouping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla Raggruppamento"])},
    "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espandi"])},
    "deleteCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina Caricatore"])},
    "removeFromGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi dal Gruppo"])},
    "deviceImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immagine del Dispositivo"])},
    "requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesto"])},
    "remindApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricorda Approvazione"])},
    "addToGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi al Gruppo"])},
    "confirmDeleteCharger": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina l'accesso al caricatore"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Perderai l'accesso al caricatore</b>!<br/><br/>Per recuperarlo, dovrai richiedere di essere aggiunto di nuovo."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])}
    },
    "confirmDeleteGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina il gruppo"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I caricabatterie ora appaiono al di fuori del gruppo."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])}
    },
    "confirmAddCharger": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricatore già presente"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già un caricatore con il numero di serie nella tua lista."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi caricatore?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrà inviata una richiesta per aggiungere il caricatore. Il caricatore rimarrà in sospeso fino a quando la richiesta non verrà approvata."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
      },
      "notFound": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricatore non trovato"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di serie non è stato trovato nel database."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])}
      }
    },
    "confirmAddGroup": {
      "alreadyExists": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo già esistente"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già un gruppo nella tua lista con questo nome."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])}
      },
      "toBeAdded": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere un'aggregazione "])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrà creata la nuova aggregazione."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
      }
    },
    "confirmSendReminder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promemoria di approvazione"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrà inviato un promemoria per approvare l'accesso al caricatore."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
    },
    "confirmRemoveFromGroup": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi dalla gruppo. Caricatore: "])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il caricatore apparirà al di fuori del gruppo."])},
      "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
      "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
    },
    "confirmAddToGroup": {
      "noGroups": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun gruppo creato."])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa il pulsante + per creare un nuovo gruppo."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetta"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
      },
      "addToGroup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi al gruppo"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il caricatore apparirà all'interno del gruppo."])},
        "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
        "cancelButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
      }
    },
    "searchingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca in corso..."])},
    "searchingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di attendere mentre controlliamo i dati."])}
  },
  "footer": {
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso Legale"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla privacy"])}
  },
  "header": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudere sessione"])}
  },
  "home": {
    "1year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 anno"])},
    "30days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 giorni"])},
    "7days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 giorni"])},
    "chargeRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricariche"])},
    "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da:"])},
    "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A:"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giorni"])},
    "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo mese"])},
    "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa settimana"])},
    "thisYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quest'anno"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard LITE"])},
    "tabAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzato"])},
    "tabSupervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisore"])},
    "tabAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministratore"])}
  },
  "login": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedere"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua e-mail"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi sono dimenticato la password"])},
    "invalidLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenziali errate"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricordami"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login riuscito!"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di attendere mentre controlliamo i dati."])}
  },
  "recordsTable": {
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esportare"])},
    "exportColumnCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricatore"])},
    "exportColumnDeviceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "exportColumnEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
    "exportColumnEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia (Wh)"])},
    "exportColumnStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
    "exportColumnUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
    "exportColumnGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppo"])},
    "exportColumnCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 (kg)"])},
    "filterPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al giorno"])},
    "filterPerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al mese"])},
    "tabDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARICATORI"])},
    "tabDevicesEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
    "tabDevicesId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tabDevicesName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "tabRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RICARICHE"])},
    "tabRecordsCharger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricatore"])},
    "tabRecordsEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
    "tabRecordsEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
    "tabRecordsStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
    "tabUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UTENTI"])},
    "tabUsersEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia"])},
    "tabUsersName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricariche e consumo"])},
    "sheetTitle0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generale"])},
    "sheetTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazioni"])},
    "sheetTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per caricatore"])},
    "sheetTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per utente"])},
    "sheetTitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per gruppo"])},
    "sheetGeneralDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da :"])},
    "sheetGeneralDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A :"])},
    "sheetGeneralTotalRecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale registrazioni :"])},
    "sheetGeneralTotalEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale energia (KwH) :"])},
    "sheetGeneralTotalCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale CO2 (Kg) :"])},
    "sheetChargersTableSerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di serie"])},
    "sheetChargersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "sheetChargersTableType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "sheetUsersTableName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "sheetUsersTableEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "reset": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "checkInbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla la tua email"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "enterMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua email"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti abbiamo inviato le istruzioni per reimpostare la tua password."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di attendere mentre controlliamo i dati."])}
  },
  "usersTable": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])}
  },
  "modal": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
  },
  "request_account_deletion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina l'account dall'applicazione Veltium"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare il tuo account ?"])},
    "message2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceverai un'email con istruzioni sul processo di eliminazione."])},
    "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di attendere mentre controlliamo i dati."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "selectNewAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un nuovo amministratore per il caricatore "])},
    "mailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta inviata"])},
    "mailSentMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti abbiamo inviato un'email con istruzioni su come eliminare il tuo account."])},
    "mailSentMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non lo trovi, controlla la cartella dello spam e assicurati di aver inserito correttamente il tuo indirizzo email."])},
    "mailSentMessage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non ricevi ancora l'email, contattaci all'indirizzo "])}
  },
  "confirm_account_deletion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm account deletion"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your account?"])},
    "message2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicking on the button below will delete your account."])},
    "confirmButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm deletion!!!"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait! we are processing the request."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "selectNewAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a new administrator for the charger"])},
    "linkInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link è invalido o scaduto."])}
  }
}