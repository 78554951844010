<template>
  <!-- ==================== CONTENIDOS ==================== -->
  <div class="login-contenido">

  <div class="mainFrame" id="loginMainFrame">

      <div v-if="errorSendingEmail" :class="warning">
          <div id="title" class="warning-title">
            {{$t('request_account_deletion.alert_title') }}</div>
          <div id="message" class="warning-text">
            {{ $t('request_account_deletion.alert_msg') }}</div>
      </div>
      <div class="msg-normal-grande"><br>{{title}}<br></div>
      <!-- salto de línes -->
      <br>

      <div class="msg-normal">{{ message1 }}</div>
      <br>
      <div class="msg-normal">{{ message2 }} ...</div>
      <br>
      <div class="msg-normal" v-if="mail_sent || errorSendingEmail  ">
        <b>usersupport@veltium.com</b>
      </div>
      <br>

      <vee-form :validation-schema="request_account_deletionSchema"
      @submit="request_account_deletion(errors)" v-show="!mail_sent"
      v-slot="{errors}">

      <!-- email -->
      <vee-field type="text" name="email" class="login-input" maxlength="32"
          v-model="email"
          :placeholder="$t('request_account_deletion.email')"
          test-data="emailInput"
          :class="{'login-input-warning ': hasErrors(errors, 'email')}"/>
      <div class="warning"><ErrorMessage name="email" /></div>

      <!-- submit -->
      <input type="submit" class="login-button-delete" maxlength="32"
          :disabled="request_account_deletion_in_submission"
          test-data="sendButton"
          :value="$t('request_account_deletion.send')"
          />

        </vee-form>

      <!-- Back to Login -->
      <div class="forgot">
      <!-- <router-link to="/requestAccountDeletion">
          {{$t('request_account_deletion.back')}}</router-link> -->
          <!-- link, run methon back-->
          <a v-on:click="reset">{{$t('request_account_deletion.back')}}</a>

      </div>

  </div>

  </div>
  <!-- ==================== /CONTENIDOS ==================== -->
  </template>
<script>
// import { auth } from '@/includes/firebase';
import '../assets/veltium-login.css';
import { sendDeletionAccountEmail } from '../includes/api-service';

export default {
  name: 'request_account_deletion',
  data() {
    return {
      request_account_deletionSchema: {
        email: 'required|email',
      },
      title: this.$t('request_account_deletion.title'),
      message1: this.$t('request_account_deletion.message'),
      message2: this.$t('request_account_deletion.message2'),
      email: '',
      request_account_deletion_in_submission: false,
      mail_sent: false,
      errorSendingEmail: false,
    };
  },
  methods: {
    async request_account_deletion(errors) {
      document.body.style.cursor = 'wait';

      // check in haserrors if the field email has errors
      const valid = !this.hasErrors(errors, 'email');

      if (!valid || this.email.trim() === '') {
        return;
      }
      console.log('request_account_deletion');
      this.request_account_deletion_in_submission = true;
      this.request_account_deletion_alert_msg = this.$t('request_account_deletion.wait');
      // set mouse pointer to hourglass
      document.body.style.cursor = 'wait';
      // add disabled class to button
      this.request_account_deletion_in_submission = true;

      try {
        // Show a modal to request the action using sweetalert2

        await sendDeletionAccountEmail(this.email, this.$i18n.locale);
        this.request_account_deletion_in_submission = false;
        this.mail_sent = true;
        this.errorSendingEmail = false;
        this.title = this.$t('request_account_deletion.mailSent');
        this.message1 = this.$t('request_account_deletion.mailSentMessage1');
        this.message2 = this.$t('request_account_deletion.mailSentMessage2');
        // set mouse pointer to default
        document.body.style.cursor = 'default';
      } catch (error) {
        console.log('Error:', error);
        this
          .this.request_account_deletion_in_submission = false;
        this.errorSendingEmail = true;
        this.mail_sent = false;
        // set mouse pointer to default
        document.body.style.cursor = 'default';
      }
    },
    hasErrors(errors, field) {
      return ((errors === undefined) ? false : (field in errors));
    },
    reset() {
      if (this.mail_sent) {
        this.mail_sent = true;
        this.errorSendingEmail = false;
        this.$router.push('/login');
      } else {
        this.mail_sent = false;
        this.errorSendingEmail = false;
        this.$router.push('/login');
      }
    },
  },
};
</script>
