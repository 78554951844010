<template>
  <!-- ==================== CONTENIDOS ==================== -->
  <div class="login-contenido">

  <div class="mainFrame" id="loginMainFrame">

      <img id="logoVeltium" src="assets/img/logo-veltium.svg" />

      <!-- Login message -->
      <div :class="login_alert_variant" style="text-align:center"
      v-show="login_show_alert">
      <span>{{ login_alert_msg }}</span>
      </div>

      <vee-form :validation-schema="loginSchema"
     @submit="login" v-slot="{errors}" >

      <vee-field type="text" name="email" class="login-input" maxlength="32"
           :placeholder="$t('login.enterMail')"
           :class="{'login-input-warning ': hasErrors(errors, 'email')}"
           test-data="emailInput"/>
      <div class="warning"><ErrorMessage name="email" /></div>

      <vee-field type="password" name="password" class="login-input" maxlength="32"
          :placeholder="$t('login.password')"
          :class="{'login-input-warning ': hasErrors(errors, 'password')}"
          test-data="passwordInput"/>
      <div class="warning"><ErrorMessage name="password" /></div>

      <div class="remember">
        <vee-field type="checkbox" name="persist" value="persist"/>
        &nbsp;{{$t('login.rememberMe')}}
      </div>

      <input type="submit" class="login-button"
      :disabled="login_in_submission"
      :value="$t('login.access')"
      />

      </vee-form>

      <div class="forgot"><a href="reset"
          test-data="resetLink">{{$t('login.forgotPassword')}}</a></div>
      <!-- <div class="forgot"><a href="register"
          test-data="registerLink">{{$t('login.register')}}</a></div> -->

  </div>

  </div>
  <!-- ==================== /CONTENIDOS ==================== -->
  </template>
<script>
import '../assets/veltium-login.css';

export default {
  name: 'Login',
  data() {
    return {
      loginSchema: {
        email: 'required|email',
        password: 'required|min:6|max:32',
        persist: '',
      },
      login_in_submission: false,
      login_show_alert: false,
      login_alert_variant: 'msg-normal',
      login_alert_msg: this.$t('login.wait'),
    };
  },
  methods: {
    async login(values) {
      this.login_show_alert = true;
      this.login_in_submission = true;
      this.login_alert_variant = 'msg-normal';
      this.login_alert_msg = this.$t('login.wait');

      try {
        await this.$store.dispatch('login', values);
      } catch (error) {
        this.login_in_submission = false;
        this.login_alert_variant = 'msg-error';
        this.login_alert_msg = this.$t('login.invalidLogin');
        return;
      }
      this.login_alert_variant = 'msg-normal-green';
      this.login_alert_msg = this.$t('login.success');
      const redirectTo = this.$route.query.redirect || '/';
      this.$router.push(redirectTo);
    },
    hasErrors(errors, field) {
      return ((errors === undefined) ? false : (field in errors));
    },

  },
};
</script>
