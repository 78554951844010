<template>
  <!-- ==================== CONTENIDOS ==================== -->
  <div class="login-contenido">

  <div class="mainFrame" id="loginMainFrame">

      <!-- MODAL -->
      <Modal
      :is-open="modalData.isOpen"
      :icon-src="modalData.iconSrc"
      :title="modalData.title"
      :text="modalData.text"
      :confirm-button-class="modalData.confirmButtonClass"
      :confirm-button-text="modalData.confirmButtonText"
      :show-cancel-button="modalData.showCancelButton"
      @confirm-action="modalData.confirmAction"
      @cancel-action="modalData.cancelAction"
      />

      <img id="logoVeltium" src="assets/img/logo-veltium.svg" />

      <div class="msg-normal-grande"><br>{{title}}<br></div>
      <!-- salto de línes -->
      <br>

      <br>

      <div class="msg-normal" v-if="invalidLink" >
        {{ $t('confirm_account_deletion.linkInvalid') }}
      </div>

      <div class="msg-normal" v-if="!invalidLink">{{$t('confirm_account_deletion.selectNewAdmin')}}:

      </div>
      <br>
      <br>

      <template v-for="device in userDevices.newAdminsArray" :key="device.device.id">

      <!-- select component to let the user select which user will be
       the next administrator of the device. Show is this.usersToPromote ==true -->
      <div id="device.device.id" v-if="device" class="">
        <div class="msg-normal">
            {{ device.device.name_for_user }}:
            &nbsp;&nbsp;
        <select v-model="selectedUser" @change="selectUser($event, device.device.id)">
          <option v-for="user in device.newAdmins"
            :value="user.email" :key="user.id" >{{ user.email }}</option>
        </select>

      </div>
      <br>
      </div>

    </template>
    <br>
    <div class="msg-normal">{{ message2 }}</div>
    <br>
      <!-- submit -->
      <input type="submit" class="login-button-delete" maxlength="32"
          :disabled="confirm_account_deletion_in_submission"
          test-data="sendButton"
          :value="$t('confirm_account_deletion.send')"
          :click="deleteAccountAction({email: email})"
          />
      <!-- Back to Login -->
      <div class="forgot" v-show="!mail_sent">
      <router-link to="/login">{{$t('confirm_account_deletion.back')}}</router-link>
      </div>

  </div>

  </div>
  <!-- ==================== /CONTENIDOS ==================== -->
  </template>
<script>

import '../assets/veltium-login.css';
import Modal from '@/components/Modal.vue';
import { checkAdmin, confirmAccountDeletion } from '../includes/api-service';

export default {
  name: 'confirm_account_deletion',
  components: {
    Modal,
  },
  data() {
    return {
      modalData: {
        isOpen: false,
        iconSrc: '/assets/img/ico-warn.svg',
        title: '',
        text: [],
        confirmButtonClass: '',
        confirmButtonText: '',
        confirmAction: null,
        showCancelButton: null,
        cancelAction: null,
      },
      confirm_account_deletionSchema: {
        email: 'required|email',
      },
      email: '',
      locale: '',
      token: '',
      title: this.$t('confirm_account_deletion.message'),
      message2: `${this.$t('confirm_account_deletion.message2')} ${this.$route.query.email}`,
      confirm_account_deletion_in_submission: false,
      confirm_account_deletion_show_alert: false,
      confirm_account_deletion_alert_variant: 'bg-white-500',
      confirm_account_deletion_alert_msg: this.$t('confirm_account_deletion.wait'),
      mail_sent: false,
      userDevices: [],
      selectedDevicesUsers: [],
    };
  },
  computed: {

  },
  methods: {
    async deleteAccountAction() {
      // check that all selects have a user selected
      if (this.selectedDevicesUsers.length !== this.userDevices.length) {
        // show the modal to inform the user that all devices need to have a new admin

        this.modalData.title = this.$t('confirm_account_deletion.error');
        this.modalData.text = [this.$t('confirm_account_deletion.allDevicesNeedAdmin')];
        this.modalData.confirmButtonClass = 'btn-danger';
        this.modalData.confirmButtonText = this.$t('confirm_account_deletion.ok');
        this.modalData.confirmAction = () => {
          this.modalData.isOpen = false;
        };
        this.modalData.showCancelButton = false;
        this.modalData.cancelAction = null;
        this.modalData.isOpen = true;
      }
    },
    reset() {
      this.email = '';
      this.$router.push('login');
    },
    usersToPromote(deviceId) {
      console.log('usersToPromote', deviceId);

      return true;
    },
    selectUser(event, deviceid) {
      console.log('selectUser', deviceid, event.target.value);
      // first delete from selectedDevicesUsers the device if it is already there
      this.selectedDevicesUsers = this.selectedDevicesUsers
        . filter((user) => user.deviceId !== deviceid);
      // add the user to the list of users to promote together with the device ID
      this.selectedDevicesUsers.push({ deviceId: deviceid, email: event.target.value });
    },
    unSelectUser(event) {
      console.log('unSelectUser', event.target.value);
      // remove the user from the list of users to promote
      this.selectedDevicesUsers = this.selectedDevicesUsers
        . filter((user) => user.email !== event.target.value);
    },
    async deleteAccount() {
      this.confirm_account_deletion_show_alert = true;
      this.confirm_account_deletion_in_submission = true;
      this.confirm_account_deletion_alert_variant = 'bg-white-500';
      this.confirm_account_deletion_alert_msg = this.$t('confirm_account_deletion.wait');

      try {
        await confirmAccountDeletion(this.email, this.token, this.selectedDevicesUsers);
      } catch (error) {
        this.confirm_account_deletion_in_submission = false;
        this.confirm_account_deletion_alert_variant = 'bg-red-500';
        this.confirm_account_deletion_alert_msg = this.$t('confirm_account_deletion.error');
      }
    },
  },
  // get the email from the route (GET parameter) nad locale and token
  created() {
    console.log('created', this.$route.query.email, this.$route.query.locale, this.$route.query.token);
    this.email = this.$route.query.email || 'anonymous';
    this.locale = this.$route.query.locale || 'en';
    this.token = this.$route.query.token || '-';

    if (this.email === 'anonymous') {
      this.confirm_account_deletion_show_alert = true;
      this.confirm_account_deletion_alert_variant = 'bg-red-500';
      this.confirm_account_deletion_alert_msg = this.$t('confirm_account_deletion.error');
    }

    // call to the API to check if the user is admin of any device, and need to promote another user

    this.modalData.isOpen = false;

    checkAdmin(this.email, this.token)
      .then((response) => {
        console.log('checkAdmin', response);
        if (response.result === 'success') {
          this.userDevices = response.data;
        }
      })
      .catch((error) => {
        console.log('checkAdmin', error);
      });
  },
};
</script>
