<template>
  <div :class="{ 'modal': true, 'none': !isOpen }">
    <div class="bubble">
      <div class="title-row">
        <div class="icon"><img alt="" :src="iconSrc"></div>
        <div class="title">{{title}}</div>
      </div>
      <div class="text-row">
        <div class="selector-wrapper">
          <div class="input-with-clear">
            <input
              v-model="selectedLocation"
              :list="locationListId"
              @focus="showOptions = true"
              @blur="hideOptions"
            />
            <button v-if="selectedLocation" @click="clearSelection" class="clear-button">×</button>
          </div>
          <datalist :id="locationListId" v-show="showOptions">
            <option v-for="location in groupList" :key="location.id" :value="location.name">
              </option>
          </datalist>
        </div>
      </div>
      <div class="buttons-row">
        <input @click="confirmAction" type="button" :class="confirmButtonClass"
          :value="confirmButtonText">
        <input @click="cancelAction" type="button" class="button-cancelar"
        v-if="showCancelButton" :value="$t('modal.cancel')">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    title: String,
    text: String,
    confirmButtonClass: String,
    confirmButtonText: String,
    showCancelButton: Boolean,
    groupList: Array,
  },
  emits: ['confirm-action', 'cancel-action'],
  data() {
    return {
      selectedLocation: '',
      locationListId: `locationListId${Math.floor(Math.random() * 1000)}`,
      showOptions: false,
    };
  },
  computed: {
    formattedText() {
      return this.text;
    },
    iconSrc() {
      if (this.confirmButtonClass === 'button-warn') {
        return 'assets/img/ico-warn.svg';
      }
      if (this.confirmButtonClass === 'button-error') {
        return 'assets/img/ico-error.svg';
      }
      return 'assets/img/ico-warn.svg';
    },
  },
  methods: {
    confirmAction() {
      console.log('confirmAction', this.selectedLocation);
      this.$emit('confirm-action', {
        selectedLocation: this.selectedLocation,
      });
    },
    cancelAction() {
      this.$emit('cancel-action');
    },
    clearSelection() {
      this.selectedLocation = '';
    },
    hideOptions() {
      setTimeout(() => {
        this.showOptions = false;
      }, 200);
    },
  },
};
</script>

<style scoped>
.selector-wrapper {
  position: relative;
}

.input-with-clear {
  position: relative;
}

.clear-button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #888;
}
</style>
