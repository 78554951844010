<template>
  <div class="panel-usuarios">

<div class="flex">
<!-- panel-row -->
<div class="panel-row">
<div class="panel-celda-icono">
  <img id="ico-gente" src="assets/img/user.svg" class="iconoPanel"></div>
<div class="panel-titulo">{{$t('usersTable.title')}}</div>
</div>
<!-- /panel-row -->
</div><!-- /flex -->

<!-- ----------------- -->
<!-- CABECERA DE TABLA -->
<div class="gridDatos gridDatosUsers cabecera">
<div>
<input ref="checkAll"
   test-data="checkAllUsers"
   type="Checkbox" @click="onSelectAll"
   :checked="selectedUsers.length === users.length"
   name="0user" id="0user">
   <label for="0user"></label><strong>{{$t('usersTable.name')}}</strong></div>
<div><strong>{{$t('usersTable.email')}}</strong></div>
</div>
<!-- /CABECERA DE TABLA -->
<!-- ------------------ -->

<!-- CONTENEDOR DE TRS DE DATOS -->
<div class="gridDatosContainer">

<!-- ITERATION -->
<template v-for="user in users" :key="user.docID"
   test-data="user">

<div class="gridDatos gridDatosUsers">
 <div>
 <input type="Checkbox"
       :value="user.docID"
       :checked="isSelected(user.docID)"
       @click="onClick"
       :name="'cb'+user.docID" :id="'cb'+user.docID">
       <label :for="'cb'+user.docID"></label>{{user.name}}&nbsp;{{user.surname}}</div>

 <div><a href="#">{{user.email}}</a></div>

</div>

</template>
<!-- /ITERATION -->

</div><!-- /gridDatosContainer -->
<!-- /CONTENEDOR DE TRS DE DATOS -->

</div>
</template>

<script>
export default {
  name: 'UsersTable',
  emits: ['user-selected', 'select-all'],
  data() {
    return {
      selectedUsers: [],
    };
  },
  props: {
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    onClick(e) {
      const currentValue = e.target.value;
      if (e.target.checked) {
        this.selectedUsers.push(currentValue);
      } else {
        this.selectedUsers = this.selectedUsers.filter((item) => item !== currentValue);
      }
      this.$emit('user-selected', e);
    },
    onSelectAll(e) {
      if (e.target.checked) {
        this.selectedUsers = [];
        this.users.forEach((item) => this.selectedUsers.push(item.docID));
      } else {
        this.selectedUsers = [];
      }
      this.$emit('select-all', e);
    },
    isSelected(d) {
      return this.selectedUsers.includes(d);
    },
    selectAll() {
      this.selectedUsers = [];
      this.users.forEach((item) => this.selectedUsers.push(item.docID));
    },
  },
};
</script>
