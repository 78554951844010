<template>
<!-- ==================== CONTENIDOS ==================== -->
<div class="login-contenido">

<div class="login-contenido-caja">

<!-- Contenido RESET EMAIL -->
<div align="center">
<div class="login-logo-cell">
  <img src="assets/img/logo-veltium.svg"
    alt="VELTIUM" id="logo-veltium-login" height="122" width="auto">
</div>
<!-- Register message -->
<div class="login-cell"
   v-if="register_show_alert" :class="register_alert_variant">
   <div id="title" class="login-mensaje-title">{{ register_alert_title }}</div>
    <div id="message" class="login-mensaje-text">{{ register_alert_msg }}</div>
</div>
<vee-form :validation-schema="registerSchema"
   @submit="register" v-show="!mail_sent"
   v-slot="{errors}">
<!-- Email -->
<div class="login-cell">
      <vee-field type="text" name="email" class="login-input"
         :placeholder="$t('register.enterMail')"
         test-data="emailInput"
         :class="{'login-input-warning ': hasErrors(errors, 'email')}"/>
      <ErrorMessage name="email" />
</div>
<!-- Password -->
<div class="login-cell">
      <vee-field type="password" name="password" class="login-input"
         :placeholder="$t('register.password')"
         test-data="passwordInput"
         :class="{'login-input-warning ': hasErrors(errors, 'password')}"/>
      <ErrorMessage name="password" />
      </div>
<!-- Confirm password -->
<div class="login-cell">
      <vee-field type="password" name="confirmPassword" class="login-input"
         :placeholder="$t('register.confirmPassword')"
         test-data="confirmPasswordInput"
         :class="{'login-input-warning ': hasErrors(errors, 'confirmPassword')}"/>
      <ErrorMessage name="confirmPassword" />
</div>
<!-- Submit button -->
<div class="login-cell"><input type="submit" class="login-button"
   :disabled="register_in_submission"
   test-data="sendButton"
    :value="$t('register.send')"/>
</div>
</vee-form>
<!-- Submit button -->
<div class="login-cell"><input type="submit" class="login-button"
   :disabled="register_in_submission" v-show="mail_sent" @click="$router.push('login')"
   test-data="continueButton"
    :value="$t('register.continue')"/>
</div>
<!-- Back to Login -->
<div class="login-cell" align="right" v-show="!mail_sent">
   <router-link class="asiempreverde" to="/login">{{$t('register.back')}}</router-link>
</div>

</div>
<!-- /Contenido RESET EMAIL -->

</div>

</div>
<!-- ==================== /CONTENIDOS ==================== -->
</template>
<script>
import { auth } from '@/includes/firebase';
import '../assets/veltium-login.css';

export default {
  name: 'register',
  data() {
    return {
      registerSchema: {
        email: 'required|email',
      },
      register_in_submission: false,
      register_show_alert: false,
      register_alert_variant: 'bg-white-500',
      register_alert_msg: this.$t('register.wait'),
      mail_sent: false,
    };
  },
  methods: {
    async register(values) {
      this.register_show_alert = true;
      this.register_in_submission = true;
      this.register_alert_variant = 'bg-white-500';
      this.register_alert_title = '';
      this.register_alert_msg = this.$t('register.wait');

      try {
        // TODO: set the user language based on the the default for sending the emails
        await auth.sendPasswordRegisterEmail(values.email);
      } catch (error) {
        this.register_in_submission = false;
        this.register_alert_title = this.$t('register.checkInbox');
        this.register_alert_msg = this.$t('register.success');
        this.mail_sent = true;
        return;
      }
      this.register_in_submission = false;
      this.register_alert_title = this.$t('register.checkInbox');
      this.register_alert_msg = this.$t('register.success');
      this.register_show_alert = true;
      this.mail_sent = true;
    },
    hasErrors(errors, field) {
      return ((errors === undefined) ? false : (field in errors));
    },
  },
};
</script>
